import { ComparisonFilters } from '@/enums'

const isNumber = v => !isNaN(+v)
const isInteger = v => v?.length === 0 || /^[\d]+$/.test(v)
const isFloat = v => v?.length === 0 || /^[\d]+.[\d]+$/.test(v)
const isColor = v => v?.length === 0 || /^#([0-9a-f]{6}|[0-9a-f]{3})$/i.test(v)
const isJson = v => {
  try {
    JSON.parse(v)
    return true
  } catch {
    return false
  }
}
const isUrl = (v, protocol = '') => {
  try {
    const url = new URL(v)
    return !protocol ? true : url.protocol === protocol
  } catch {
    return false
  }
}

const isUUIDv4 = v =>
  v?.length === 0 ||
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(v)

const isDate = v => !isNaN(Date.parse(v))

/**
 * Validate `v` object like it do backend. Algorithm:
 *
 *      Iterate over filter fields:
 *      If filter field value empty (null or undefined):
 *          Ignore that filter field
 *      If v[filter field value] is empty:
 *          Ignore that filter field
 *      Iterate over rules of filter field value:
 *          If any rule valid:
 *              Go to next filter field value
 *          If all rule invalid:
 *              Reject v
 *      If all filter field value valid:
 *          Accept v
 *      If any filter field value invalid:
 *          Reject v
 *
 * @param v Object which should be validated. Example:
 *
 *   ```json
 *   {
 *     "id": "93ae4c2f-61e8-4844-874a-a38ccb52ee54",
 *     "user_username": "superadmin",
 *     "status": "Draft"
 *   }
 *   ```
 * @param restFilterObj Object with filter. Contain multiple field, each field can contain multiple
 *   rules. Example:
 *
 *   ```javascript
 *   // Filter object
 *   {
 *     "user_username": "superadmin", // <-- filter field (entire value after `:` char)
 *     "status": [
 *       "Success", // <-- filter rule (one of values inside filter field)
 *       "Failure"
 *     ]
 *   }
 * ```
 *
 * @returns {boolean} Does `v` meets all the requirements from `restFilterObj`
 */
const validateRestFilters = (v, restFilterObj) => {
  /**
   * @param expectedVal Val from server object. For example `In progress`
   * @param actualVal Val from filter object (e.g. "rule"), for example `ne:Draft`
   *
   * @returns {boolean} Does variables contains the same values.
   */
  const compareWithOpWord = (expectedVal, actualVal) => {
    const comparators = {
      [ComparisonFilters.GE]: (a, b) => a >= b,
      [ComparisonFilters.GT]: (a, b) => a > b,
      [ComparisonFilters.LE]: (a, b) => a <= b,
      [ComparisonFilters.LT]: (a, b) => a < b,
      [ComparisonFilters.NE]: (a, b) => a !== b,
      [ComparisonFilters.EQ]: (a, b) => a === b,
      default: () => true,
    }
    /* There can be `split(':', 1)` but in JS limit param define not how much times should split,
    but count of elements which will be returned. */
    const parts = expectedVal.split(':')
    const op = parts[0]
    let expected = parts.slice(1, parts.length).join(':')
    // Dates fields in object comes from server as String. Other types parsed automatically.
    if (isDate(actualVal)) [actualVal, expected] = [Date.parse(actualVal), Date.parse(expected)]
    return (comparators[op.toLowerCase()] || comparators.default)(actualVal, expected)
  }

  /* Iterate over filter's fields instead of object's field because it's allow to immediate break stop entire function
  on first mismatch. */
  for (const [filterK, filterV] of Object.entries(restFilterObj ?? {})) {
    /* If filter value set as `null` or `undefined`, server ignore that rule, so we are too.
    Do not replace to `if (!filterV)` here and in next lines!!! Because if filter would be { someVal: false } -
    `continue` branch will execute, but it shouldn't. We need real emptiness here, not logical. */
    if (filterV === null || filterV === undefined) continue
    const valToCheck = v[filterK]
    /* If in object no field with name the same as filter field name - it's useless filter field,
    and we can go to next filter field. */
    if (valToCheck === null || valToCheck === undefined) continue
    /* filter field can contain multiple rules, for example: username: ['a', 'b'].
    For convenience wrap single rule into array. */
    const filterRules = filterV instanceof Array ? filterV : [filterV]
    /* Rules works like `or` operator. So we use `some()` function.
     But if all rules in filter failed - we can reject object entirely .*/
    const someRulePassed = filterRules.some(rule => {
      /*
          Rules can be simple (for example: `created_date: '2025-01-20T15:36:41.030413Z'`), or with comparison word:
          `created_date: 'gt:2025-01-20T15:36:41.030413Z'`. That's why using native equality operator `===` not enough.
          Also, we should consider type of field (Date, String, Number, Boolean).
          */
      const isRuleWithComparisonWord = Object.values(ComparisonFilters).some(it =>
        // extra `toString()` call to correct filters with non-string types
        rule.toString().startsWith(`${it}:`)
      )
      /* If it's rule with comparison word - pass it to word check function as is.
          If it's simple rule - add word for equality check (`ComparisonFilters.EQ`) and pass in then pass to function.
          The made for code simplicity to avoid rewrite comparison for different types.
          */
      const unifiedRule = isRuleWithComparisonWord ? rule : `${ComparisonFilters.EQ}:${rule}`
      return compareWithOpWord(unifiedRule, valToCheck) === true
    })
    if (!someRulePassed) {
      console.debug('object rejected because:', { filterV, valToCheck })
      return false
    }
  }
  return true
}

export default {
  isNumber,
  isInteger,
  isFloat,
  isColor,
  isUrl,
  isUUIDv4,
  isDate,
  validateRestFilters,
  rules: {
    isNumber: v => !v || isNumber(v) || 'Need to be a number',
    isRequired: v => !!v || 'Field is required',
    isInteger: v => !v || isInteger(v) || 'Need to be an integer',
    isFloat: v => !v || isFloat(v) || 'Need to be a float',
    isColor: v => !v || isColor(v) || 'Need to be hex color',
    isJson: v => !v || isJson(v) || 'Need to be json',
    isUrl: v => !v || isUrl(v) || 'Need to be valid URL string',
    isUUIDv4: v => !v || isUUIDv4(v) || 'Need to be UUIDv4',
    isDate: v => !v || isDate(v) || 'Need to be valid Date',
  },
}
